body{
	background-color:#F9F9F9;
}
.round{
	border-radius: 32px;
}
.panel-round{
	min-height:90vh;
	width:100%;
	background:white;
	border-radius:32px;
	padding:0px;
}

input{
	&:focus:not([readonly]) {
		border-bottom: 1px solid #093559 !important;
		box-shadow: 0 1px 3px 0 #093559 !important;
	}
}
input[type=text]:not(.browser-default):focus:not(readonly)+label{
color:black !important;
}
strong {
	font-weight: bold;
}
.dashed{
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23626262FF' stroke-width='2' stroke-dasharray='9' stroke-dashoffset='39' stroke-linecap='square'/%3e%3c/svg%3e");
	background-color:#F8F8F8;
	border-radius: 12px;
	width:200px;
	height:100px;
	margin-bottom:10px;
	padding:10px;
}
@media only screen and (max-width: 1441px){
	.table1 {
		font-size:10px !important;
	}
}
@media only screen and (max-width: 992px){
	.modal {
		width: 80% !important;
	}
}
.switch label input[type=checkbox]:checked + .lever {
	background-color: #135790;
}
.switch label input[type=checkbox]:checked + .lever:after {
	background-color: #093559;
}


@media only screen and (max-width: 992px){
	#nav {
		display: none !important;
	}
	.side-button{
		display:block !important;
	}
}
#nav{
	background:#F9F9F9;
	border-right:solid #D3D7CF 1px;
	height:100%;
	padding:0px;
}
.sidebar{
	border-top-left-radius:32px;
	border-bottom-left-radius:32px;
	min-height: 90vh;
	width:300px;
	background-color:#fff;
	position:fixed!important;
	z-index:1;
	overflow:auto;
	transition: width 1s;
	width:0;
	.close{
		position:absolute;
		right:20px;
		width:10px;
		height:10px;
		color:black;
		padding:10px;
	}
}
.collapsible{
	box-shadow:none;
	border:none;
	a{
		display:block;
		height:50px;
		line-height:4px;
		vertical-align:middle;
	}
}
.collapsible-header{
	border:none;
	font-weight:bold;
	font-size:18px;
}

.side-button{
	border:none;
	font-weight:bold;
	font-size:18px;
	display:none;
}
.title-sub{
	background: linear-gradient(to right, white, #E0E0E0);
	display:inline-block;
	padding-bottom:5px;
	margin:10px 0px 10px 0px;
	.title{
		font-size:18px;
		background:white;
		font-weight:bold;
	}
}
.input-field > label:not(.label-icon).active {
    transform: translateY(-19px) translateX(-10px) scale(0.8) !important;
}
.file-path-wrapper{
	.close-x{
		border-radius:50px;
		width:20px;
		height:20px;
		padding:0px;
		line-height:0px;
		display:flex;
		justify-content: center;
		align-items:center;
		float:right;
		&:hover{
			background:#F0F0F0;
			box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
		}
	}
}
.delete-circle{
		width:40px;
		height:40px;
		&:hover{
			border-radius:50px;
			background:#F0F0F0;
			box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
		}
}
.input-field{
	label{
		left:10px;
	}
}
.collapsible-header {
	background:none !important;
}

#collapsi{
	a{
		height:60px !important;
		display:block;
		padding:10px;
		&:hover{
			background:#e9e9e9;
		}
	}
}
